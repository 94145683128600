import React from "react"
import styled from "styled-components"

const Main = styled.main`
  max-width: 930px;
  background: #DDD;
  margin: 4rem auto;
`
const HeaderContent = styled.div`
  max-width: 930px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`
const Header = styled.header`
  background: white;
  color: #333; 
  position: fixed;
  left: 0;
  right: 0;
  height: 40px;
  top: 0;
  box-shadow: 1px 1px #888888;
`

const NavItems = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`
const NavItem = styled.li`
    display: inline-block;
    padding: 10px;
`
const UserInfo= styled.section`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: repeat(3, auto);
    > h1 {
      margin: 0px;
    }
    > p {
      grid-column: 1/-1;
    }
  @media (min-width: 700px) {
    grid-template-columns: 1fr 2fr;
    > p {
      grid-column: auto/-1;
      grid-row: 3;
    }
  }
`
const Avatar = styled.img`
  border-radius: 50%;
  width: 70px;
  justify-self: center;
  @media (min-width: 700px) {
    width:152px;
    grid-row: 1/-1;
  }
`
const FeedNav = styled.div`
  display: flex;
  justify-content: center;
`
const FeedNavItem = styled.a`
	display: inline-block;
	padding: 10px;
`
const StatsItem= styled.span`
	display: inline-block;
	padding: 0px 10px 0px 0px;
`
const NewsFeed = styled.section`
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 5px;
@media (min-width: 700px) {
  grid-gap:28px;
}
`
const NewsFeedItem = styled.img`
	width:100%;
`

const Searacha= styled.input`
  display:none;
@media (min-width: 700px) {
  display:block;
}
`

const UserStats= styled.div`
  grid-column: 1/-1;
  grid-row: 3;
  display:flex;
  justify-content: space-around;
@media (min-width: 700px) {
  grid-row:auto;
  grid-column: auto;
  display:block;
  padding: 0 10px 0 0;
}
`

const Page = ({location}) => (
  <>
  <Header class="header">
  <HeaderContent>
  <div class="logo">
    Logo
  </div>
  <Searacha type="text" placeholder="search" />
  <nav class="main-nav">
    <NavItems>
      <NavItem>Home</NavItem>
      <NavItem>Icon</NavItem>
      <NavItem>Icon 2</NavItem>      
    </NavItems>
  </nav>
  </HeaderContent>
</Header>
<Main>
  <UserInfo>
    <Avatar src="http://via.placeholder.com/293x293" alt="" />
      <h1>Dan Thompson</h1>
      <UserStats>
        <StatsItem>49 posts</StatsItem>
        <StatsItem>950 followers</StatsItem>
        <StatsItem>57 following</StatsItem>
      </UserStats>
      <p>Look at this stuff! I took it from here: https://www.youtube.com/user/LevelUpTuts www.dan.band</p>
  </UserInfo>
  <FeedNav>
    <FeedNavItem href="#">Posts</FeedNavItem>
    <FeedNavItem href="#">Saved</FeedNavItem>
  </FeedNav>
  <NewsFeed>
    <NewsFeedItem src="http://via.placeholder.com/293x293" />
    <NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" /><NewsFeedItem src="http://via.placeholder.com/293x293" />
  </NewsFeed>

</Main>
</>
)

export default Page
